import React, { useState, useRef, useEffect } from 'react';
import { useLabels } from 'wsm-common-data';
import { isBrowser } from 'ws-scripts/modules/environment';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';
import Tooltip from './Tooltip';
import { HIDE_SHOW_PAYMENTS_TOOLTIP_KEY } from '../global-constants';
import { setHideTooltip, getHideTooltip } from '../utilities/wiapiSettings';

/* eslint jsx-a11y/label-has-associated-control: [1] */
const Switch = ({ isOn, handleToggle, label }) => {
	const elementRef = useRef(null);
	const popperRef = useRef(null);
	const [arrowRef, setArrowRef] = useState(null);

	// Delay load the tooltip to allow for the MyWallet integration and correct attachment to the switch.
	const [isTooltipDelayed, setIsTooltipDelayed] = useState(true);
	useEffect(() => {
		const wiapiLoadedTimer = setTimeout(() => {
			setIsTooltipDelayed(false);
		}, 3000);
		return () => clearTimeout(wiapiLoadedTimer);
	}, []);

	const [isTooltipShown, setIsTooltipShown] = useState(
		!window.sessionStorage.getItem(HIDE_SHOW_PAYMENTS_TOOLTIP_KEY)
	);

	const labels = useLabels();

	const { styles, attributes } = usePopper(
		elementRef.current,
		popperRef.current,
		{
			modifiers: [
				{
					name: 'arrow',
					options: {
						element: arrowRef
					}
				},
				{
					name: 'offset',
					options: {
						offset: [4, 12]
					}
				}
			],
			placement: 'bottom-end'
		}
	);

	const handleNotNow = () => {
		setIsTooltipShown(false);
		setHideTooltip();
	};

	let switchLabelClass = '';

	// Kind of a hack for now until we implement this properly through the design system.
	if (isBrowser) {
		switchLabelClass =
			window?.DDC?.dataLayer?.site?.design?.themekit?.includes('_BLACK')
				? 'text-white'
				: 'text-black';
	}

	const notNowLinkColor =
		window?.DDC?.dataLayer?.site?.design?.themekit?.includes('DARK')
			? 'text-link'
			: 'text-link text-link-override';

	const handlePersonalizePayments = () => {
		setIsTooltipShown(false);
		setHideTooltip();
		handleToggle(true, true);
	};
	return (
		<>
			<input
				checked={isOn}
				onChange={handleToggle}
				className="switch-checkbox d-none"
				id="switch"
				type="checkbox"
			/>
			<span
				className={`switch-label ${
					isOn ? switchLabelClass : 'text-muted'
				}`}
			>
				{label}
			</span>
			<label
				htmlFor="switch"
				className={
					isOn ? 'switch-slider active' : 'switch-slider inactive'
				}
				ref={elementRef}
			>
				<span className="switch-button" />
			</label>
			<Tooltip
				popperRef={popperRef}
				styles={styles}
				attributes={attributes}
				setArrowRef={setArrowRef}
				isShown={
					!isOn &&
					isTooltipShown &&
					!getHideTooltip() &&
					!isTooltipDelayed
				}
			>
				<p>
					<strong>{labels.get('NEW_EXCLAMATION')}</strong>{' '}
					{labels.get('CUSTOMIZE_YOUR_PAYMENTS_VERBIAGE')}
				</p>
				<div className="d-flex align-items-center">
					<button
						type="button"
						className={`ml-auto ${notNowLinkColor}`}
						onClick={handleNotNow}
					>
						{labels.get('NOT_NOW')}
					</button>
					<button
						type="button"
						className="btn btn-primary ml-4"
						onClick={handlePersonalizePayments}
					>
						{labels.get('PERSONALIZE_PAYMENTS')}
					</button>
				</div>
			</Tooltip>
		</>
	);
};

Switch.propTypes = {
	isOn: PropTypes.bool,
	handleToggle: PropTypes.func,
	label: PropTypes.string
};

export default Switch;
