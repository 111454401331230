import PropTypes from 'prop-types';
import React from 'react';
import { useLabels } from 'wsm-common-data';
import { getFilterLabels } from '../utilities/filters';

// Render an applied filters count component like
// "2 filters", and a comma separated list of the filter names
// Intentionally hidden from screen readers in favor of FacetBreadcrumbList
const AppliedFilters = ({ isScrolled, appliedFilters }) => {
	const labels = useLabels();
	const count = appliedFilters.length;
	if (!count) {
		return null;
	}

	let label = count === 1 ? labels.get('X_FILTER') : labels.get('X_FILTERS');
	label = label.replace('{count}', count);

	return (
		<span
			className="text-muted d-lg-none"
			role="presentation"
			aria-hidden="true"
		>
			{isScrolled ? label : getFilterLabels(appliedFilters)}
		</span>
	);
};

AppliedFilters.propTypes = {
	isScrolled: PropTypes.bool.isRequired,
	appliedFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default AppliedFilters;
