import { useRef, useCallback } from 'react';

export const useDrag = () => {
	const clicked = useRef(false);
	const dragging = useRef(false);
	const position = useRef(0);

	const dragStart = useCallback((ev) => {
		position.current = ev.clientX;
		clicked.current = true;
	}, []);

	const dragStop = useCallback(() => {
		// NOTE: need some delay so item under cursor won't be clicked
		setTimeout(() => {
			clicked.current = false;
			dragging.current = false;
		}, 0);
	}, []);

	const dragMove = (ev, cb) => {
		const newDiff = position.current - ev.clientX;

		const movedEnough = Math.abs(newDiff) > 5;

		if (clicked.current && movedEnough) {
			dragging.current = true;
		}

		if (dragging.current && movedEnough) {
			position.current = ev.clientX;
			cb(newDiff);
		}
	};

	return {
		dragStart,
		dragStop,
		dragMove,
		dragging,
		position
	};
};
