export const DIRECTION = {
	RIGHT: 'right',
	LEFT: 'left'
};

export const WIDGET_NAME = 'ws-inv-filters';
export const SHOW_PAYMENTS_NOTIFICATION = `${WIDGET_NAME}/show-payments-notification`;

export const HIDE_SHOW_PAYMENTS_TOOLTIP_KEY = 'userHideShowPaymentsTooltip';
export const SHOW_PAYMENTS_LOCAL_STORAGE_KEY = 'userShowPayments';
export const WIAPI_SETTINGS_STORAGE_KEY = 'wiapiSettings';

export const POPULAR_FILTERS = 'popular filters';
export const FEATURES = 'features';
export const NONE = 'none';
