import React, { useState, memo, useEffect } from 'react';
import { isBrowser } from 'ws-scripts/modules/environment';
import { useLabels } from 'wsm-common-data';
import { EstimatedLocationMarkup, LocationModal } from 'wsm-location-modal';
import PropTypes from 'prop-types';

const EstimatedLocationWrapper = ({ isMobile, postalCode }) => {
	const [isOpenModal, setOpenModal] = useState(false);
	const labels = useLabels();

	useEffect(() => {
		const subscriber = window?.DDC?.pubsub?.subscribe(
			'location-modal/show-modal',
			() => {
				setOpenModal(true);
			}
		);

		return () => {
			subscriber?.unsubscribe();
		};
	}, []);

	if (!isBrowser) {
		return <></>;
	}

	const onCloseModal = () => {
		setOpenModal(false);
	};

	const onOpenModal = () => {
		setOpenModal((previous) => !previous);
	};

	const modalTitle = labels.get('YOUR_LOCATION');

	const onChangeSelectedStore = () => {
		window?.DDC?.pubsub?.publish('location-modal/update-store');
	};

	const onChangeUserPostalCode = (input) => {
		window.DDC.InvData.setFilterSelections({
			filterName: 'geoZip',
			filterValue: [input],
			filterValues: [input]
		});
	};

	return (
		<>
			{isMobile && (
				<EstimatedLocationMarkup
					userPostalCode={postalCode}
					onClick={onOpenModal}
					containerClassNames={[
						'd-flex',
						'flex-wrap',
						'align-items-center',
						'mb-4'
					]}
				/>
			)}
			<LocationModal
				userPostalCode={postalCode}
				title={modalTitle}
				onClose={onCloseModal}
				isOpen={isOpenModal}
				isMobile={isMobile}
				onChangeSelectedStore={onChangeSelectedStore}
				onChangeUserPostalCode={onChangeUserPostalCode}
				persistUserStore
			/>
		</>
	);
};

export default memo(EstimatedLocationWrapper);

EstimatedLocationWrapper.propTypes = {
	isMobile: PropTypes.bool.isRequired,
	postalCode: PropTypes.string
};
