import { useEffect, useMemo, useCallback } from 'react';

export function scrollWithRAF(element, distance, inMilliSeconds) {
	// negative distance means: scroll to left | top
	const { distanceX = 0, distanceY = 0 } = distance;
	// milliseconds
	let startTime;

	// current position
	const { scrollLeft } = element;
	const { scrollTop } = element;

	// Calculate max scrolls
	// content width - viewport width
	const maxScrollX = element.scrollWidth - element.clientWidth;
	// content height - viewport height
	const maxScrollY = element.scrollHeight - element.clientHeight;
	const bufferOffSet = 5;

	function process(timestamp) {
		// first run
		if (startTime === undefined) {
			startTime = timestamp;
			requestAnimationFrame(process);
			return;
		}

		const elapsedTime = timestamp - startTime;
		const progress = elapsedTime / inMilliSeconds;

		// x-coor we want to move to
		const offsetX = progress * distanceX;

		// y-coor we want to move to
		const offsetY = progress * distanceY;

		// x-coor to scroll to
		let scrollToXAxis = scrollLeft + offsetX;
		let notOverX = true;

		// over left
		if (scrollToXAxis < 0) {
			scrollToXAxis = 0;
			notOverX = false;
			// over right
		} else if (scrollToXAxis > maxScrollX) {
			scrollToXAxis = maxScrollX + bufferOffSet;
			notOverX = false;
		}

		// y-coor to scroll to
		let scrollToYAxis = scrollTop + offsetY;
		let notOverY = true;
		if (scrollToYAxis < 0) {
			scrollToYAxis = 0;
			notOverY = false;
		} else if (scrollToYAxis > maxScrollY) {
			scrollToYAxis = maxScrollY + bufferOffSet;
			notOverY = false;
		}
		element.scrollTo(scrollToXAxis, scrollToYAxis);
		// next round
		if (progress < 1) {
			if (notOverX || notOverY) {
				requestAnimationFrame(process);
			}
		} else {
			// last round
			element.scrollTo(scrollToXAxis, scrollToYAxis);
		}
	}

	// kick off
	requestAnimationFrame(process);
}

export const useWheelEffect = (scrollContainerRef, distance = 100) => {
	const throttledHorizontalScroll = useMemo(
		() => window._.throttle(scrollWithRAF, 100),
		[]
	);

	const throttledWheel = window._.throttle((distanceX) => {
		throttledHorizontalScroll(
			scrollContainerRef.current,
			{ distanceX, distanceY: 0 },
			100
		);
	}, 100);

	const onWheelHandler = useCallback(
		(e) => {
			const { deltaX, deltaY } = e;
			// Detect content's scrolling direction on touchpad
			const delTa = Math.abs(deltaX) < Math.abs(deltaY) ? deltaY : deltaX;
			let distanceX = 0;
			// Horizontal scroll
			if (delTa === deltaY) {
				e.preventDefault();
				distanceX = deltaY > 0 ? distance : -distance;
				throttledWheel(distanceX);
				// Limit values' scroll amount to avoid sensitive touchpad
			} else if (Math.abs(delTa) < 10) {
				e.preventDefault();
			}
		},
		[distance, throttledWheel]
	);

	useEffect(() => {
		const ele = scrollContainerRef.current;
		if (!ele) {
			return () => {};
		}
		ele.addEventListener('wheel', onWheelHandler);
		return () => {
			ele.removeEventListener('wheel', onWheelHandler);
		};
	}, [onWheelHandler, scrollContainerRef]);
};
