/* eslint-disable jsx-a11y/label-has-for */
// this rule is deprecated so I'm ignoring it.
// https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/label-has-for.md

import React from 'react';
import PropTypes from 'prop-types';
import { useRequestData, useLabels } from 'wsm-common-data';
import { trackEvent } from 'ddc-track-event';

const InventorySort = ({ sortOptions /* displayStyle */ }) => {
	const { widgetName, windowId } = useRequestData();
	const labels = useLabels();

	const handleChange = (e) => {
		const { value } = e.target;

		trackEvent(widgetName, windowId, {
			element: 'Sort select',
			elementCTA: value,
			fieldValue: value,
			result: 'Results updated with sort selection'
		});

		window.DDC.InvData.setSortBy(value || '');

		window.DDC.pushToGtmDataLayer({
			event: 'ddc.form.change',
			eventData: {
				element: e.target,
				event: 'ddc.form.change',
				formEventId: '',
				label: sortOptions.find((o) => o.value === value).label,
				linkType: 'select-one',
				widgetName: 'ws-inv-listing'
			}
		});
	};

	// TODO: Uncomment this once the utility class for sort dropdown is finalized

	// const sortClasses = `form-control ${
	// 	displayStyle === 'transparent' ? 'form-control-transparent' : ''
	// }`.trim();

	return sortOptions && sortOptions.length ? (
		<React.Fragment>
			<label htmlFor="sortBy" className="sr-only">
				{labels.get('SORT_BY')}
			</label>
			<select
				id="sortBy"
				className="form-control font-size-ios-zoom-override"
				value={window.DDC.InvData.getSortBy()}
				onChange={handleChange}
			>
				{sortOptions.map(({ value, label }) => (
					<option key={value} value={value}>
						{label}
					</option>
				))}
			</select>
		</React.Fragment>
	) : null;
};

InventorySort.propTypes = {
	sortOptions: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string
		})
	)
	// displayStyle: PropTypes.string
};

InventorySort.defaultProps = {
	sortOptions: []
	// displayStyle: ''
};

export default InventorySort;
