import { useLayoutEffect, useRef, useEffect, useCallback } from 'react';

function usePrevious(value) {
	// The ref object is a generic container whose current property is mutable ...
	// ... and can hold any value, similar to an instance property on a class
	const ref = useRef();
	// Store current value in ref
	useEffect(() => {
		ref.current = value;
	}, [value]); // Only re-run if value changes
	// Return previous value (happens before update in useEffect above)
	return ref.current;
}

export function useScrollPosition(effect, facetLength, element, wait) {
	const throttleTimeout = useRef(null);

	const preLenght = usePrevious(facetLength);

	const callBack = useCallback(() => {
		effect();
		throttleTimeout.current = null;
	}, [effect]);

	useLayoutEffect(() => {
		if (!element) {
			return () => {};
		}
		const hasOverFlow = element.scrollWidth > element.clientWidth;
		// Recall effect if there is any change in deps after init or container not hasOverFlow at the first time render
		if ((preLenght && preLenght !== facetLength) || !hasOverFlow) {
			callBack();
		}
		const handleScroll = () => {
			if (wait) {
				if (throttleTimeout.current === null) {
					throttleTimeout.current = setTimeout(callBack, wait);
				}
			} else {
				callBack();
			}
		};
		// Trigger if scroll element
		element.addEventListener('scroll', handleScroll);
		// Trigger if screen size is change
		window.addEventListener('resize', handleScroll);

		return () => {
			element.removeEventListener('scroll', handleScroll);
			window.removeEventListener('resize', handleScroll);
		};
	}, [callBack, facetLength, element, preLenght, wait]);
}
