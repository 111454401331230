import { trackEvent } from 'ddc-track-event';

/**
 * Uses the InvData.setFacetSelection method to define update to facets and fires
 * a tracking event.
 * @param {object} e An event
 * @param {string} facetId A string for facet id
 * @param {string} facetType A string for facet type
 * @param {string} value A string for facet value
 * @param {string} widgetName A string for widgetName
 * @param {string} windowId A string for windowId
 */
export const addFilter = ({ e, facetId, value, widgetName, windowId }) => {
	const appliedFilters = window.DDC.InvData.getAppliedFilters();
	const selectedValues =
		appliedFilters.find((filter) => filter.id === facetId)?.values || [];

	let newSelectedIds = [];

	selectedValues.forEach((selected) => newSelectedIds.push(selected.value));
	// Remove selected value from array if already included
	newSelectedIds = newSelectedIds.filter((item) => item !== value);
	// Add facet id to list of selected ids
	newSelectedIds.push(value);

	window.DDC.InvData.setFilterSelections({
		filterName: facetId,
		filterValue: value,
		filterValues: newSelectedIds,
		isChip: false,
		isSelected: newSelectedIds.includes(value),
		isSlider: false,
		element: e && e.target
	});

	if (newSelectedIds.includes(value)) {
		trackEvent(widgetName, windowId, {
			action: 'applied',
			element: `${facetId} chip`,
			elementCTA: value,
			fieldValue: value,
			result: 'Results updated with facet added'
		});
	}
};
