import PropTypes from 'prop-types';
import React from 'react';
import { setClassNames } from 'ddc-classnames-js';

const FilterItemList = ({ minBreakpoint, showFirst, children }) => {
	const classes = ['my-1'];
	const classesBreakpoints = [];

	if (minBreakpoint !== 'xs') {
		classesBreakpoints.push('d-none');
		classesBreakpoints.push(`d-${minBreakpoint}-inline-block`);
	}

	return (
		<ul className="facet-filter-list mb-0 list-inline">
			{React.Children.map(children, (child, i) => {
				const liClassNames =
					i !== 0 && showFirst
						? setClassNames([...classes, ...classesBreakpoints])
						: setClassNames([...classes]);
				return <li className={liClassNames}>{child}</li>;
			})}
		</ul>
	);
};

FilterItemList.propTypes = {
	showFirst: PropTypes.bool,
	minBreakpoint: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
	children: PropTypes.node.isRequired
};

FilterItemList.defaultProps = {
	showFirst: false,
	minBreakpoint: 'lg'
};

export default FilterItemList;
