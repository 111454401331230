export const formatAppliedFilters = (appliedFilters = [], labels) => {
	return appliedFilters.map((obj) => {
		const { id, label, values } = obj;
		const formattedFilter = obj;
		const idsToFormat = [
			'geoRadius',
			'normalExteriorColor',
			'normalInteriorColor'
		];
		if (idsToFormat.includes(id)) {
			formattedFilter.values = values.map((value) => {
				const { label: filterLabel, value: filterValue } = value;
				let displayedValue = filterLabel;
				if (id === 'geoRadius') {
					const { geoZip } = window.DDC.InvData.getGeoData()
						? window.DDC.InvData.getGeoData()
						: window.DDC.InvData.urlParams;
					displayedValue = `${filterValue} ${labels.get(
						'MILES'
					)} ${labels.get('FROM_LOWERCASE')} ${geoZip}`;
				}
				return {
					label:
						id === 'geoRadius'
							? displayedValue
							: `${label}: ${displayedValue}`,
					value: filterValue
				};
			});
		}
		return formattedFilter;
	});
};

/**
 * Returns a string of joined applied filter value labels separated by a comma.
 *
 * @param {array} appliedFilters An array consisting of `window.DDC.InvData.getAppliedFilters()` data
 *
 * @returns {string} label text of filter values joined by a comma
 */
export const getFilterLabels = (appliedFilters = []) => {
	const labels = [];

	if (appliedFilters.length) {
		const values = appliedFilters.map((obj) => obj.values);
		[].concat(...values).map((value) => labels.push(value.label));
	}

	return labels.join(', ');
};

export const getFeaturedPromotionFilter = (labels) => {
	return {
		id: 'featuredPromotion',
		label: labels.get('FEATURED_PROMOTION'),
		values: [
			{
				id: 'featuredPromotion',
				label: labels.get('FEATURED_PROMOTION')
			}
		]
	};
};

/**
 * This method filters out valid facet in URL params
 * @param {object} urlParams
 * @returns
 */
export const filterUrlParams = (urlParams) => {
	const knowFacetParams = [
		'compositeType',
		'year',
		'make',
		'model',
		'trim',
		'bodyStyle',
		'odometer',
		'paymentLoan',
		'normalExteriorColor',
		'normalInteriorColor',
		'normalInteriorMaterial',
		'normalTransmission',
		'normalDriveLine',
		'normalFuelType',
		'packages',
		'gvOption',
		'internetPrice',
		'accountCity',
		'accountState',
		'normalBodyStyle',
		'category',
		'cityFuelEconomy',
		'driveLine',
		'invoicePrice',
		'msrp',
		'paymentLease',
		'retailValue',
		'engine',
		'combinedFuelEfficiency',
		'range',
		'gvBodyStyle',
		'gvMake',
		'gvModel',
		'gvYear',
		'highwayFuelEconomy',
		'inventoryDate',
		'status',
		'superModel',
		'type'
	];
	return Object.keys(urlParams)
		.filter((key) => knowFacetParams.includes(key))
		.filter((key) => typeof urlParams[key] !== 'object' || urlParams[key])
		.reduce((urlFilers, key) => {
			if (Array.isArray(urlParams[key])) {
				if (
					key === 'normalInteriorColor' ||
					key === 'normalExteriorColor'
				) {
					const newFormatFilters = [];
					urlParams[key].forEach((val) => {
						if (!val) {
							return;
						}

						newFormatFilters.push(
							key === 'normalInteriorColor'
								? `${'Interior Color'}: ${val}`
								: `${'Exterior Color'}: ${val}`
						);
					});
					return [...urlFilers, ...newFormatFilters];
				}
				return [
					...urlFilers,
					...urlParams[key].filter((val) => val !== '')
				];
			}
			if (key === 'normalInteriorColor') {
				return [...urlFilers, `Interior Color: ${urlParams[key]}`];
			}

			if (key === 'normalExteriorColor') {
				return [...urlFilers, `Exterior Color: ${urlParams[key]}`];
			}

			return [...urlFilers, urlParams[key]];
		}, []);
};
