import { isBrowser } from 'ws-scripts/modules/environment';

/**
 * Returns a JavaScript object parsed from JSON stored as a string in browser storage
 *
 * @param {string} type A string that is either 'sessionStorage' or 'localStorage' to identify the type of storage you wish to access.
 * @param {string} key A string that is the key of the browser storage item you wish to retrieve.
 *
 * @returns {object} A JavaScript object parsed from the JSON object found in browser storage
 */
export const browserStorageParseJson = (type, key) => {
	if (!isBrowser) {
		return {};
	}
	const storage = window[type];

	let item;

	try {
		item = JSON.parse(storage.getItem(key));
	} catch (err) {
		// Return null if storage item is not present.
	}

	return item;
};
