import { useState, useEffect } from 'react';
import { isBrowser } from 'ws-scripts/modules/environment';

export const useScrollEffect = (ref, navbarHeight) => {
	const [isScrolled, setScroll] = useState(false);
	const filterPosition = ref.current ? ref.current.offsetTop : 0;
	const filterPositionCalc = filterPosition - navbarHeight;

	useEffect(() => {
		if (isBrowser && ref && ref.current) {
			document.addEventListener(
				'scroll',
				window._.debounce(() => {
					setScroll(window.scrollY >= filterPositionCalc);
				}, 0)
			);
		}
	}, [ref, filterPosition, filterPositionCalc]);

	return isScrolled;
};
