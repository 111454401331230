import { POPULAR_FILTERS, FEATURES, NONE } from '../global-constants';

/**
 * Formats array of suggested filters.
 * @param {array} suggestionsArr This is `facets.suggestedFilters`. For an example, see `mockSuggestedFilters` in `test/fixtures/suggestedFilters.js`.
 * @returns {array} A formatted array of suggested filters. For an example, see `mockFormattedSuggestions` in `test/fixtures/suggestedFilters.js`.
 */
export const formatSuggestedFilters = (suggestionsArr = []) => {
	const transformedSuggestions = suggestionsArr.map((suggestion) => {
		const facetValue = suggestion.values?.[0] || {};
		const { values: rangeValues, ...valueProperties } = facetValue;

		const newSuggestion = {
			facetId: suggestion.id,
			facetLabel: suggestion.label,
			...valueProperties
		};

		if (newSuggestion.type === 'USER_RANGE') {
			const totalCount =
				rangeValues?.reduce(
					(total, item) => total + (item?.count ?? 0), // optional chaining needed because item may be null
					0
				) ?? 0;
			return {
				// This value is used to create the URL params
				value: `${newSuggestion.min}-${newSuggestion.max}`,
				count: totalCount,
				...newSuggestion
			};
		}

		return newSuggestion;
	});

	return transformedSuggestions;
};

/**
 * Takes a facet object and returns a formatted array of facet values.
 * @param {object} facetObject A facet object from the `facets.facets` array.
 * For an example, see `mockFeaturesFacetObject` in `test/fixtures/facets.js`.
 * @param {string} facetObject.id
 * @param {string} facetObject.label
 * @param {array} facetObject.values
 * @returns {array} A formatted array of facet values that will serve as the suggested filters.
 * For an example, see `mockFeaturesFacetValues` in `test/fixtures/facets.js`.
 */
export const formatFacetValues = ({ id, label, values = [] }) => {
	return values.reduce((updatedArray, value) => {
		// Filter out already-selected facet values, which don't need to be suggested to the user
		if (!value.selected) {
			updatedArray.push({
				facetId: id,
				facetLabel: label,
				...value
			});
		}
		return updatedArray;
	}, []);
};

/**
 * Gets the suggested filters that are configured to display and formats them correctly.
 * @param {string} suggestionType A string denoting which type of suggested filter chips to display on the page.
 * Suggested filters can come from either `facets.suggestedFilters` or the values of the `gvOption` facet.
 * @param {object} facetsObject The response from the `getFacets` request.
 * @returns A formatted array of objects (facet values). These are the suggested filters to display.
 */
export const getSuggestedFilters = (suggestionType, facetsObject) => {
	switch (suggestionType) {
		case POPULAR_FILTERS:
			return formatSuggestedFilters(facetsObject?.suggestedFilters);
		case FEATURES:
			// gvOption is the "Features" facet
			return formatFacetValues(
				facetsObject?.facets?.find((item) => item.id === 'gvOption') ||
					{}
			);
		case NONE:
		default:
			return [];
	}
};
