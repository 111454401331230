import React from 'react';
import PropTypes from 'prop-types';

const Tooltip = ({
	isShown,
	popperRef,
	styles,
	attributes,
	setArrowRef,
	children
}) => {
	return (
		isShown && (
			<div
				ref={popperRef}
				className="container-tooltip"
				style={styles.popper}
				{...attributes.popper}
			>
				<div ref={setArrowRef} style={styles.arrow} className="arrow" />
				{children}
			</div>
		)
	);
};

Tooltip.propTypes = {
	isShown: PropTypes.bool,
	popperRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
	styles: PropTypes.shape({
		arrow: PropTypes.shape({}),
		popper: PropTypes.shape({})
	}),
	attributes: PropTypes.shape({
		popper: PropTypes.shape({})
	}),
	setArrowRef: PropTypes.func,
	children: PropTypes.node.isRequired
};

export default Tooltip;
