import PropTypes from 'prop-types';
import React from 'react';
import { useRequestData, useLabels } from 'wsm-common-data';
import { removeAllFilters } from 'wsm-srp-utilities';

const ClearFilters = ({ buttonClasses }) => {
	const label = useLabels().get('CLEAR_FILTERS');
	const { widgetName, windowId } = useRequestData();
	return (
		<button
			type="button"
			className={`facet-filter text-link ${buttonClasses}`}
			onClick={() => removeAllFilters(label, widgetName, windowId)}
		>
			{label}
		</button>
	);
};

ClearFilters.propTypes = {
	buttonClasses: PropTypes.string
};

ClearFilters.defaultProps = {
	buttonClasses: ''
};

export default ClearFilters;
