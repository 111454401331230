import React from 'react';
import PropTypes from 'prop-types';
import { useRequestData, useLabels } from 'wsm-common-data';
import { formatNumber } from 'ddc-intl';
import { trackEvent } from 'ddc-track-event';

// Render a vehicle count component like
// "42 vehicles matching"
// Responsively shows shorter label that is hidden from screen readers
// If the prop "displayStyle" comes in as "modalButton", render as a button.

const VehicleCount = ({ count, displayStyle, setModalShow, hasFilters }) => {
	const { locale, widgetName, windowId } = useRequestData();
	const labels = useLabels();

	// define feature flag for scroll to top listing on filter
	const handleModalClose = (setModalShowFn) => {
		trackEvent(widgetName, windowId, {
			element: 'Show vehicles button',
			elementCTA: 'Show "X" Vehicles',
			result: 'Facet screen hidden. Results show.'
		});

		setModalShowFn(false);
		// scroll to top of listings on mobile facets after submitting
		window.DDC.scrollToAnchor($('[data-widget-id="inventory-filters1"]'), {
			heightAdj: $('.header-minimal-container').height()
		});
	};

	const countLabels = hasFilters
		? [
				labels.get('X_MATCHING'),
				count === 1
					? labels.get('X_VEHICLE_MATCHING')
					: labels.get('X_VEHICLES_MATCHING'),
				count === 1
					? labels.get('SHOW_X_MATCH')
					: labels.get('SHOW_X_MATCHES')
		  ]
		: [
				count === 1
					? labels.get('X_VEHICLE')
					: labels.get('X_VEHICLES'),
				count === 1
					? labels.get('X_VEHICLE')
					: labels.get('X_VEHICLES'),
				count === 1
					? labels.get('SHOW_X_VEHICLE')
					: labels.get('SHOW_X_VEHICLES')
		  ];

	const [
		shortVehicleCountLabel,
		longVehicleCountLabel,
		buttonVehicleCountLabel
	] = countLabels.map((label) =>
		label.replace('{count}', formatNumber(count, locale))
	);

	if (displayStyle === 'modalButton') {
		return (
			<button
				type="button"
				className="btn btn-primary btn-block"
				onClick={handleModalClose.bind(this, setModalShow)}
			>
				<span className="d-none" role="presentation" aria-hidden="true">
					{shortVehicleCountLabel}
				</span>
				{buttonVehicleCountLabel}
			</button>
		);
	}

	if (count === null) {
		return null;
	}

	return (
		<strong className="d-inline-block flex-shrink-0 mr-3">
			<span className="d-sm-none" role="presentation" aria-hidden="true">
				{shortVehicleCountLabel}
			</span>
			<span className="d-none d-sm-inline">{longVehicleCountLabel}</span>
		</strong>
	);
};

VehicleCount.propTypes = {
	count: PropTypes.oneOfType([
		PropTypes.oneOf([null]).isRequired,
		PropTypes.number.isRequired
	]).isRequired,
	hasFilters: PropTypes.bool.isRequired,
	displayStyle: PropTypes.string,
	setModalShow: PropTypes.func
};

VehicleCount.defaultProps = {
	displayStyle: '',
	setModalShow: () => {}
};

export default VehicleCount;
