import { tabbable } from 'tabbable';

/**
 * Trap focus inside element
 *
 * @param {HTMLElement} element A element to trap focus
 *
 * @returns {Function} A callback to deactivate focus trap
 */
export const trapFocus = (element) => {
	const focusableEls = tabbable(element);

	const firstFocusableEl =
		focusableEls?.length > 0 ? focusableEls[0] : undefined;
	const lastFocusableEl =
		focusableEls?.length > 0
			? focusableEls[focusableEls.length - 1]
			: undefined;

	const KEYCODE_TAB = 9; // Tab key

	if (!firstFocusableEl) {
		// Element have no focusable element
		return false;
	}

	const trapActivate = (e) => {
		const isTabPressed = e.key === 'Tab' || e.keyCode === KEYCODE_TAB;

		if (!isTabPressed) {
			return;
		}

		if (e.shiftKey) {
			/* shift + tab */ if (document.activeElement === firstFocusableEl) {
				lastFocusableEl.focus();
				e.preventDefault();
			}
		} /* tab */ else if (document.activeElement === lastFocusableEl) {
			firstFocusableEl.focus();
			e.preventDefault();
		}
	};

	element.addEventListener('keydown', trapActivate);

	return () => element.removeEventListener('keydown', trapActivate);
};
