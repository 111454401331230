import { useLabels } from 'wsm-common-data';

const SpellcheckMsg = () => {
	const label = useLabels().get('SEARCH_INSTEAD_FOR');

	const setOriginalSearchTerm = () => {
		window.DDC.InvData.setOriginalSearchTerm();
	};
	return (
		<div>
			{label}&nbsp;
			<button
				type="button"
				className="text-link"
				onClick={() => setOriginalSearchTerm()}
			>
				&quot;{window.localStorage.getItem('originalSearchTerm')}&quot;
			</button>
		</div>
	);
};

export default SpellcheckMsg;
