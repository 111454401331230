import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useRequestData, useLabels } from 'wsm-common-data';
import { trackEvent } from 'ddc-track-event';
import { setClassNames } from 'ddc-classnames-js';
import ClearFilters from './ClearFilters';
import FilterItem from './FilterItem';
import FilterItemList from './FilterItemList';
import InventorySort from './InventorySort';
import VehicleCount from './VehicleCount';
import { trapFocus } from '../utilities/trapFocus';

const FilterModalButton = ({ sortOptions, count, appliedFilters }) => {
	const [showModal, setModalShow] = useState(false);
	const [animationName, setAnimationName] = useState(null);
	const { widgetName, windowId } = useRequestData();
	const labels = useLabels();
	const buttonLabel = labels.get('FILTER_SLASH_SORT');
	const initialFocusRef = useRef(null);
	// define feature flag for scroll to top listing on filter

	const hideModal = (scrollToListings = true) => {
		// Remove animation className and allow animation transition before removing modal
		setAnimationName(null);
		setTimeout(() => {
			setModalShow(false);
			if (scrollToListings) {
				// scroll to top of listings on mobile facets after submitting
				window.DDC.scrollToAnchor(
					$('[data-widget-id="inventory-filters1"]'),
					{
						heightAdj: $('.header-minimal-container').height()
					}
				);
			}
		}, 300);
	};

	const onShowModal = () => {
		setModalShow(true);
		window.dispatchEvent(
			new CustomEvent('wiapiPlaceholderAvailable', {
				detail: {
					location: 'search-facet-pricing'
				}
			})
		);
	};

	window.DDC.pubsub.subscribe(
		'ws-inv-filters/show-filters-modal',
		onShowModal
	);

	window.DDC.pubsub.subscribe(
		'ws-inv-filters/close-filters',
		(scrollToListings) => {
			hideModal(scrollToListings);
		}
	);

	const supportARIA = (modal) => {
		const modalWrap = modal.closest('[aria-hidden]');
		if (modalWrap) {
			modalWrap?.removeAttribute('aria-hidden');

			// Listen for modal in modal
			window?.DDC?.pubsub?.subscribe('wsm-common-modal/closed', () => {
				modalWrap?.removeAttribute('aria-hidden');
			});
		}
	};

	const onEntered = () => {
		const modal = document.querySelector('.modal.dock-full');
		if (modal) {
			initialFocusRef?.current?.focus();
		}
	};

	useEffect(() => {
		if (showModal) {
			window.DDC.InvData.publishEvent('ws-inv-filters/modal-show');

			let deActivateFocusTrap = null;

			// Add animation className after modal element is found
			const updateModal = () => {
				const modal = document.querySelector('.modal.dock-full');
				if (modal) {
					supportARIA(modal);
					setTimeout(() => setAnimationName('slide'), 50);
					// eslint-disable-next-line no-use-before-define
					clearInterval(intervalModal);
					deActivateFocusTrap = trapFocus(modal);
				}
			};
			const intervalModal = setInterval(updateModal, 50);

			trackEvent(widgetName, windowId, {
				element: 'Filter/sort button',
				elementCTA: 'Filter/Sort',
				result: 'Filter panel displayed'
			});

			return () => {
				clearInterval();
				if (deActivateFocusTrap) {
					deActivateFocusTrap();
				}
			};
		} else {
			window.DDC.InvData.publishEvent('ws-inv-filters/modal-hide');
		}

		return () => {};
	}, [showModal, widgetName, windowId]);

	return (
		<React.Fragment>
			<Modal
				show={showModal}
				onHide={hideModal}
				onEntered={onEntered}
				className={setClassNames(['dock-full', animationName])}
				aria-modal="true"
				aria-labelledby="ws-inv-filters-modal-label"
			>
				<Modal.Body>
					<div className="d-flex mb-3">
						<Modal.Title
							id="ws-inv-filters-modal-label"
							className="ddc-font-size-large"
						>
							{buttonLabel}
						</Modal.Title>
						<button
							type="button"
							id="hide-filters-modal-button"
							className="text-link font-weight-bold ml-auto"
							onClick={hideModal}
							ref={initialFocusRef}
						>
							{labels.get('DONE')}
						</button>
					</div>
					{!!appliedFilters.length && (
						<React.Fragment>
							<FilterItemList minBreakpoint="xs">
								{appliedFilters.map((filter) =>
									filter.values.map((item) => (
										<FilterItem
											{...item}
											key={[filter.id, item.value].join(
												'-'
											)}
											filterId={filter.id}
											filterLabel={filter.label}
										/>
									))
								)}
								<ClearFilters
									label={labels.get('CLEAR_FILTERS')}
								/>
							</FilterItemList>
							<hr className="modal-divider" />
						</React.Fragment>
					)}
					<InventorySort {...{ sortOptions }} />
					<hr className="modal-divider mb-0" />
					<div className="modal-filters-react-portal" />
				</Modal.Body>
				<Modal.Footer className="top-gradient border-0">
					<VehicleCount
						count={count}
						displayStyle="modalButton"
						setModalShow={setModalShow}
						hasFilters={!!appliedFilters.length}
					/>
				</Modal.Footer>
			</Modal>
			<button
				type="button"
				id="show-filters-modal-button"
				className="btn btn-default btn-block ddc-font-size-large font-weight-bold"
				onClick={onShowModal}
			>
				<i className="ddc-icon ddc-icon-filter-list-controls" />{' '}
				{buttonLabel}{' '}
				{appliedFilters.length > 0 && (
					<div className="badge mb-0">{appliedFilters.length}</div>
				)}
			</button>
		</React.Fragment>
	);
};

FilterModalButton.propTypes = {
	count: PropTypes.number.isRequired,
	sortOptions: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string,
			label: PropTypes.string
		})
	),
	appliedFilters: PropTypes.arrayOf(PropTypes.object)
};

FilterModalButton.defaultProps = {
	sortOptions: [],
	appliedFilters: []
};

export default FilterModalButton;
