import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { useLabels, usePrefs } from 'wsm-common-data';
import { setClassNames } from 'ddc-classnames-js';
import { DIRECTION } from '../global-constants';

const Navigator = forwardRef(
	({ handleNavigator, direction, isMobile }, ref) => {
		const labels = useLabels();
		const { chipColorClasses } = usePrefs();
		const isRightNavigator = direction === DIRECTION.RIGHT;
		const buttonLabel = labels.get(
			`VIEW_${isRightNavigator ? 'NEXT' : 'PREVIOUS'}_SUGGESTED_FILTER`
		);

		if (isMobile) {
			return null;
		}

		return (
			<div className={`navigator-${direction}`} ref={ref}>
				<button
					className={setClassNames([
						'chip',
						chipColorClasses,
						'btn-no-decoration',
						'rounded-circle',
						'text-decoration-none',
						'd-inline-flex',
						'justify-content-center',
						'align-items-center'
					])}
					type="button"
					aria-label={buttonLabel}
					onClick={(e) => handleNavigator(e, direction)}
					title={buttonLabel}
				>
					<i
						className={`ddc-icon ddc-icon-arrow4-${direction} pointer-events-none`}
						aria-hidden="true"
					/>
				</button>
			</div>
		);
	}
);

Navigator.propTypes = {
	handleNavigator: PropTypes.func,
	direction: PropTypes.string,
	isMobile: PropTypes.bool
};

Navigator.displayName = 'Navigator';

export default Navigator;
