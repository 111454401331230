import { isBrowser } from 'ws-scripts/modules/environment';
import { browserStorageParseJson } from './browserStorage';

import {
	HIDE_SHOW_PAYMENTS_TOOLTIP_KEY,
	SHOW_PAYMENTS_LOCAL_STORAGE_KEY,
	WIAPI_SETTINGS_STORAGE_KEY
} from '../global-constants';

const getPageName = () => {
	if (!isBrowser) {
		return '';
	}
	return window?.DDC?.siteSettings?.pageAlias;
};

const getWiapiSettings = () => {
	const pageName = getPageName();
	const settings = browserStorageParseJson(
		'sessionStorage',
		WIAPI_SETTINGS_STORAGE_KEY
	);
	return settings?.[pageName] || {};
};

const getWiapiUserShowPayments = () => {
	const settings = browserStorageParseJson(
		'localStorage',
		SHOW_PAYMENTS_LOCAL_STORAGE_KEY
	);
	return settings || { show: false };
};

/**
 * @returns {boolean} Indicates if the WIAPI placeholders layout or default layout should be used.
 */
export const getPlaceholderLayout = () => {
	const wiapiSettings = getWiapiSettings();
	return wiapiSettings?.useFilterSortPlaceholders || false;
};

/**
 * @returns {boolean} Indicates if the Show Payments toggle should be displayed.
 */
export const getPaymentsEnabled = () => {
	const wiapiSettings = getWiapiSettings();
	return wiapiSettings?.showPayments;
};

/**
 * @returns {boolean} Indicates if the Show Payments toggle should start enabled or disabled.
 */
export const getUserShowPayments = () => {
	const userShowPayments = getWiapiUserShowPayments();
	return typeof userShowPayments?.show === 'boolean'
		? userShowPayments?.show
		: false;
};

export const setHideTooltip = () => {
	if (isBrowser) {
		window.sessionStorage.setItem(HIDE_SHOW_PAYMENTS_TOOLTIP_KEY, true);
	}
};

export const getHideTooltip = () => {
	if (isBrowser) {
		return window.sessionStorage.getItem(HIDE_SHOW_PAYMENTS_TOOLTIP_KEY);
	}
	return false;
};
